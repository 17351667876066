@charset "UTF-8";
/* >> Color Platte */
/* << Color Platte */
/*font family*/
/*font color*/
/*border*/
/*page*/
.bortop {
  position: relative; }
  .bortop:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1PX;
    width: 100%;
    margin: 0;
    z-index: 1;
    border-top: 1px solid #4b4b5b;
    transform-origin: 0 0;
    transform: scaleY(0.5); }

.borright {
  position: relative; }
  .borright:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1PX;
    margin: 0;
    z-index: 1;
    border-right: 1px solid #4b4b5b;
    transform-origin: 100% 0;
    transform: scaleX(0.5); }

.borbottom {
  position: relative; }
  .borbottom:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1PX;
    margin: 0;
    z-index: 1;
    border-bottom: 1px solid #4b4b5b;
    transform-origin: 0 100%;
    transform: scaleY(0.5); }

.borleft {
  position: relative; }
  .borleft:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1PX;
    margin: 0;
    z-index: 1;
    border-left: 1px solid #4b4b5b;
    transform-origin: 0 0;
    transform: scaleX(0.5); }

.join-main h3 {
  font-size: 20px; }
  .join-main h3 small {
    font-size: 14px; }

.join-main ul {
  list-style: none;
  padding: 0; }

.join-main div h5 {
  font-size: 14px;
  font-weight: bold;
  margin: .5em 0 1em; }

.join-main div a {
  display: block; }
  .join-main div a:link, .join-main div a:visited {
    color: #4b4b4b; }
  .join-main div a:hover {
    color: #2bb699; }

.join-main img {
  display: block;
  transform: scale(0.5);
  transition: all .5s; }
  @media only screen and (max-width: 500px) {
    .join-main img {
      display: none; } }

.job-content {
  overflow-y: auto;
  padding: 10px 0;
  transition: all .3s;
  position: absolute;
  -moz-box-shadow: 2px 2px 7px #aaa;
  -webkit-box-shadow: 2px 2px 7px #aaa;
  box-shadow: 2px 2px 7px #aaa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  opacity: 0;
  background: #fff; }
  .job-content .close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 2em;
    right: 2em; }
    .job-content .close img {
      width: 100%; }
  .job-content .main {
    width: 80%;
    overflow-y: auto;
    margin: 70px auto 0 auto; }
    @media only screen and (max-width: 500px) {
      .job-content .main {
        width: 90% !important;
        padding: 0; } }
    .job-content .main .top-img img {
      width: 100%; }
      @media only screen and (max-width: 500px) {
        .job-content .main .top-img img {
          display: none; } }
    .job-content .main .job-desc {
      overflow: hidden;
      overflow-y: auto;
      font-size: 14px; }
      .job-content .main .job-desc p {
        margin: 1em 0; }
      .job-content .main .job-desc li {
        padding: .2em 0; }
      @media only screen and (max-width: 500px) {
        .job-content .main .job-desc {
          height: 230px; } }

.modal-body .job-title {
  padding: .5em 0 1em;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 20px; }
  .modal-body .job-title span {
    color: #4b4b5b; }
  .modal-body .job-title strong {
    font-size: 14px;
    font-weight: 500; }
  @media only screen and (max-width: 500px) {
    .modal-body .job-title {
      padding: 1.5em 0 0 0; } }

.active .join-main img, .keep .join-main img {
  transform: scale(1); }

.active .join-main .img1, .keep .join-main .img1 {
  transition-delay: .1s; }

.active .join-main .img2, .keep .join-main .img2 {
  transition-delay: .2s; }

.active .join-main .img3, .keep .join-main .img3 {
  transition-delay: .3s; }

@media (min-width: 768px) {
  .scale {
    opacity: 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all .5s;
    transition: all .5s; }
    .scale.active {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition-delay: .25s;
      transition-delay: .25s; }
  .scale-left {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all .5s;
    transition: all .5s; }
  .slideInLeft {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: .25s;
    transition-delay: .25s; }
  .slideInRight {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: .25s;
    transition-delay: .25s; } }

/* >> Color Platte */
/* << Color Platte */
.header-title {
  color: #fff;
  text-align: center;
  margin-top: 6em; }

.solution-slide-height {
  height: 350px; }

.solution-body {
  background-color: #fafafa; }
  .solution-body .solution-main {
    max-width: 1170px;
    background-color: #fff;
    margin: 0 auto; }
    .solution-body .solution-main .solution-content {
      max-width: 960px;
      margin: 0 auto; }

.theme-box {
  background-size: cover; }
  .theme-box .theme-text {
    height: 160px;
    line-height: 160px;
    font-size: 24px;
    color: #666; }

.solution-tabs-box {
  width: 100%;
  max-width: 1170px;
  position: absolute;
  bottom: 0;
  background: #fff;
  height: 162px;
  border-bottom: 1px solid #e9eff4; }
  .solution-tabs-box .img-btn-box {
    margin: 20px;
    text-align: center;
    display: block; }
    .solution-tabs-box .img-btn-box .img {
      height: 50px;
      display: block;
      margin: 0 auto; }
    .solution-tabs-box .img-btn-box .img-active {
      display: none;
      margin: 0 auto; }
    .solution-tabs-box .img-btn-box .tab-name {
      padding-top: 10px;
      color: #ccc; }
    .solution-tabs-box .img-btn-box.active:after {
      content: '';
      display: block;
      height: 22px;
      width: 63px;
      margin: 15px auto -20px;
      background: url("../static/solution/common/小三角.png"); }
    .solution-tabs-box .img-btn-box.active .img {
      display: none; }
    .solution-tabs-box .img-btn-box.active .img-active {
      height: 50px;
      display: block;
      margin: 0 auto; }
    .solution-tabs-box .img-btn-box.active .tab-name {
      padding-top: 10px;
      color: #555; }

.white-ticket .theme-box {
  background: #f5f6fa;
  border-radius: 5px;
  margin-top: 40px; }
  .white-ticket .theme-box img {
    padding-right: 10px; }

/* >> Color Platte */
/* << Color Platte */
.about-body {
  background-color: #fafafa; }
  .about-body .about-content {
    margin: 0 auto;
    max-width: 1200px;
    background-color: #fff;
    margin: 0 auto;
    padding: 50px; }
    .about-body .about-content .about-history {
      list-style: none;
      font-style: italic;
      width: 100%;
      line-height: 78px;
      font-size: 18px;
      padding: 0; }
      .about-body .about-content .about-history li {
        border-bottom: 1px solid #e9eff4; }
  .about-body .main-content {
    max-width: 1200px;
    margin: 0 auto; }
    .about-body .main-content .herow {
      height: 570px;
      text-align: center; }
    .about-body .main-content .scene {
      width: 100%;
      text-align: center;
      height: 226px;
      position: relative; }
      .about-body .main-content .scene div {
        width: 100%;
        background: rgba(0, 146, 219, 0.7);
        color: #fff;
        height: 55px;
        line-height: 55px;
        font-size: 18px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0; }
      .about-body .main-content .scene img {
        width: 100%;
        height: 226px; }
    .about-body .main-content .join-content {
      height: 344px;
      background: #fff;
      padding: 30px 60px 0 60px;
      text-align: left; }
      .about-body .main-content .join-content a {
        color: #51545e;
        display: block;
        line-height: 40px;
        cursor: pointer; }
      .about-body .main-content .join-content a:hover {
        color: #eebb33; }
    .about-body .main-content .content {
      padding: 15px 48px;
      color: #666;
      min-height: 140px; }

/* >> Color Platte */
/* << Color Platte */
.home .img-title-box {
  background-color: transparent;
  color: #fff;
  padding: 25px 0;
  text-align: left;
  display: inline-block; }
  .home .img-title-box img {
    padding-right: 20px; }

.home .home-line {
  float: left;
  width: 30px;
  margin-left: -15px;
  padding-top: 20px; }
  .home .home-line img {
    width: 30px; }

.home .home-line-right {
  float: right;
  width: 30px;
  margin-right: -15px;
  padding-top: 20px; }
  .home .home-line-right img {
    width: 30px; }

.selected {
  display: inline-block; }

.home-icon {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-right: 10px; }

.home-icon img {
  vertical-align: sub; }

.home-text {
  padding-left: 33px; }

.phone-img {
  display: block;
  margin: 0 auto; }

#accordion p {
  color: #999; }

body {
  font-family: 'Microsoft YaHei UI', sans-serif;
  color: #555 !important;
  font-size: 16px;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body.top-padding {
  padding-top: 60px; }

::-moz-selection {
  background-color: #191b1d;
  color: #fff; }

::selection {
  background-color: #191b1d;
  color: #fff; }

img {
  max-width: 100%;
  height: auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 2px;
  font-weight: 600; }

h1,
.h1 {
  font-size: 48px; }

h2,
.h2 {
  font-size: 32px; }

h3,
.h3 {
  font-size: 23px; }

h4,
.h4 {
  font-size: 19px; }

h5,
.h5 {
  font-size: 16px; }

h6,
.h6 {
  font-size: 18px; }

.text-mini {
  font-size: 12px; }

h6.upper,
.h6.upper {
  font-size: 12px;
  font-weight: 200; }

.bold {
  font-weight: bold !important; }

.thin {
  font-weight: 200 !important; }

.biaohao {
  color: #007bdb;
  font-style: italic; }

a {
  text-decoration: none; }

a:focus {
  color: #1b0207;
  text-decoration: none; }

a.small-link {
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: 200; }

a.white-text:hover {
  color: #EF2D56; }

ul.clean {
  margin-left: 0;
  padding: 0; }
  ul.clean li {
    list-style: none;
    margin-left: 0; }

.clear-both {
  clear: both; }

.modal-content {
  border: none;
  padding: 2px 3%;
  border-radius: 3px; }

.modal-header {
  border-bottom: none; }
  .modal-header .close {
    margin-top: -10px;
    margin-right: -11px;
    font-size: 34px;
    font-weight: 200; }

.bg-white {
  background: white; }

@media (min-width: 1000px) {
  .modal-dialog {
    width: 900px;
    margin: 140px auto; } }

p {
  color: #06182C;
  line-height: 2em; }

p .db {
  display: block; }

.icon-list li {
  margin-bottom: 10px;
  list-style-type: none; }

.icon-list li i {
  margin-right: 5px; }

.form-group:last-child {
  margin-bottom: 0; }

section {
  padding: 40px 15px 100px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f8; }

section.full-height {
  min-height: 100%; }

section.grey {
  background-color: #f7f7f7; }

section.grey .form-control,
section.grey .form-select {
  border-color: #ccc; }

section.dark {
  background-color: #191b1d;
  color: #fff;
  border: 0; }

section.dark p {
  color: #e6e7e9; }

section.dark blockquote footer {
  color: #fff; }

section.dark + section.dark {
  border-top: 1px solid #101112; }

section.simple-parallax {
  height: 500px;
  background-color: transparent; }

section.splash {
  color: #fff; }

section.splash p {
  color: #fff; }

section.white {
  background-color: #fff; }

.section-content {
  padding: 25px 0;
  margin: 0 auto;
  max-width: 1170px; }

.section-content:before,
.section-content:after {
  content: " ";
  display: table; }

.section-content:after {
  clear: both; }

.text-shadow {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.28); }

.title-shadow {
  text-shadow: 0px 9px 8px rgba(0, 0, 0, 0.51); }

.no-shadow {
  box-shadow: none !important; }

.title {
  margin-top: 0;
  margin-bottom: 25px;
  position: relative;
  z-index: 10; }

.title.right {
  text-align: right; }

.title.right hr {
  margin-right: 0;
  margin-left: auto; }

.title.center {
  text-align: center; }

.title.center hr {
  margin: 30px auto; }

.title h2 {
  margin-top: 0;
  font-size: 58px; }

.title h2.upper {
  font-size: 48px;
  letter-spacing: 8px; }

.title h2.cursive {
  font-size: 64px; }

.title h3 {
  margin-top: 0;
  font-size: 42px;
  padding-top: 50px;
  line-height: 80px;
  font-weight: 400 !important; }

.title h3.upper {
  font-size: 38px;
  letter-spacing: 8px; }

.title h4 {
  font-size: 32px;
  font-weight: 400 !important;
  margin-bottom: 20px; }

.title p {
  font-size: 16px;
  margin: 0;
  font-weight: 200;
  color: #666; }

.title p.cursive {
  font-size: 38px; }

.title > i {
  font-size: 45px;
  margin-bottom: 25px; }

.title hr {
  width: 45px;
  margin: 20px 0;
  border-top: 1px solid #36508b; }

.title hr.black {
  border-color: #191b1d; }

.title hr.right-align {
  margin-left: auto;
  margin-right: 0; }

.btn-default {
  border-color: #6178ea;
  padding: 10px 25px; }

.btn.active, .btn:active {
  box-shadow: none; }

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
  background-color: #6178ea;
  border-color: #6178ea;
  color: #fff; }

.btn-dark-out:hover,
.btn-dark-out:active,
.btn-dark-out:focus {
  background-color: #191b1d;
  border-color: #191b1d;
  color: #fff; }

.titleH3 {
  font-size: 42px; }

.btn-light {
  color: #191b1d;
  border-color: #fff;
  background-color: #fff; }

.btn-light:hover,
.btn-light:active,
.btn-light:focus {
  background-color: #fff;
  border-color: #fff;
  color: #191b1d; }

.btn-light-out {
  color: #fff;
  border-color: #fff;
  background-color: transparent; }

.btn-light-out:hover,
.btn-light-out:active,
.btn-light-out:focus {
  background-color: #fff;
  border-color: #fff;
  color: #191b1d; }

.btn-color {
  color: #eff2f7;
  border-color: #4091EF;
  background-color: #4091EF; }

.btn-color:hover,
.btn-color:active,
.btn-color:focus {
  background-color: #4091EF;
  border-color: #4091EF;
  color: #fff; }

.btn-color-out {
  color: #4091EF;
  border-color: #4091EF;
  background-color: transparent; }

.btn-color-out:hover,
.btn-color-out:active,
.btn-color-out:focus {
  background-color: #36508b;
  border-color: #36508b;
  color: #fff; }

.dropdown-menu {
  border: none;
  background: rgba(59, 80, 142, 0.9);
  text-align: center;
  width: 120px !important;
  min-width: 120px;
  line-height: 30px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }
  .dropdown-menu li > a {
    color: #fff;
    font-size: 14px !important;
    line-height: 30px; }
  .dropdown-menu li > a:hover {
    color: #aabbff;
    background-color: #36508b; }

.go-top {
  width: 50px;
  height: 45px;
  position: absolute;
  top: -45px;
  line-height: 45px;
  right: 50px;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 100; }

.go-top a {
  color: #191b1d;
  display: block; }

.go-top a:hover {
  color: #4091EF; }

blockquote {
  border: 0; }

blockquote.boxed {
  background-color: #f7f7f7;
  border-left: 3px solid #191b1d; }

blockquote p {
  font-size: 20px; }

blockquote p.cursive {
  font-size: 32px; }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none; }

/*
  01
  Navbar
*/
#topnav {
  position: fixed;
  background-color: #3C434F;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  border: 0;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  min-height: 62px;
  font-size: 16px; }
  #topnav.scroll {
    background-color: #46464e;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
    #topnav.scroll .navigation-menu > li > a {
      color: #fff; }
      #topnav.scroll .navigation-menu > li > a:hover {
        color: #eee;
        background-color: #36508b; }
    #topnav.scroll .navigation-menu > li.active > a {
      color: #36508b; }
    #topnav.scroll .navbar-toggle span {
      background-color: #fff; }
    #topnav.scroll .logo {
      padding: 18px 0;
      float: left;
      line-height: 80px; }
      #topnav.scroll .logo .logo-dark {
        display: block; }
    #topnav.scroll.dark {
      background-color: #46464e; }
      #topnav.scroll.dark .navigation-menu > li > a {
        color: #fff; }
        #topnav.scroll.dark .navigation-menu > li > a:hover {
          background-color: #36508b;
          color: #ef2d56; }
      #topnav.scroll.dark .navigation-menu > li .submenu {
        background-color: #191b1d;
        border-color: #3d4146; }
      #topnav.scroll.dark .navigation-menu > li.active > a {
        color: #ef2d56; }
      #topnav.scroll.dark .menu-extras .menu-item {
        border-color: #3d4146; }
      #topnav.scroll.dark .logo .logo-dark {
        display: none; }
      #topnav.scroll.dark .logo .logo-light {
        display: block; }
      #topnav.scroll.dark .navbar-toggle span {
        background-color: #fff; }
  #topnav .navbar-toggle {
    border: 0;
    position: relative;
    width: 60px;
    height: 62px;
    padding: 0;
    margin: 0;
    cursor: pointer; }
    #topnav .navbar-toggle .lines {
      width: 25px;
      display: block;
      position: relative;
      margin: 24px auto;
      height: 18px; }
    #topnav .navbar-toggle.open span {
      position: absolute; }
      #topnav .navbar-toggle.open span:first-child {
        top: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      #topnav .navbar-toggle.open span:nth-child(2) {
        visibility: hidden; }
      #topnav .navbar-toggle.open span:last-child {
        width: 100%;
        top: 6px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    #topnav .navbar-toggle span {
      height: 1px;
      width: 100%;
      background-color: #fff;
      display: block;
      margin-bottom: 5px;
      -webkit-transition: -webkit-transform .5s ease;
      transition: -webkit-transform .5s ease;
      transition: transform .5s ease, -webkit-transform .5s ease; }
      #topnav .navbar-toggle span:last-child {
        width: 75%;
        margin-bottom: 0; }
    #topnav .navbar-toggle:hover {
      background-color: transparent; }
      #topnav .navbar-toggle:hover span {
        background-color: #fff; }
    #topnav .navbar-toggle:focus {
      background-color: transparent; }
  #topnav .navigation-menu {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0; }
    #topnav .navigation-menu > li {
      float: left;
      display: block;
      position: relative; }
      #topnav .navigation-menu > li > a {
        display: block;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 200;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        line-height: 20px;
        padding: 5px 0 30px;
        margin: 25px 15px 0;
        min-height: 32px; }
        #topnav .navigation-menu > li > a.active {
          border-bottom: 3px solid #eebb33; }
        #topnav .navigation-menu > li > a .submenu li a i {
          margin-right: 5px; }
        #topnav .navigation-menu > li > a .submenu li span.label {
          padding: .2em .6em .3em;
          display: inline;
          background-color: #36508b;
          color: #fff;
          margin-left: 4px;
          font-weight: bold;
          line-height: 1; }
  #topnav .logo {
    float: left;
    padding: 21px 0;
    margin-left: 0;
    -webkit-transition: all 1s ease;
    transition: all .6s ease; }
    #topnav .logo img {
      -webkit-transition: all 1s ease;
      transition: all 1s ease; }
    #topnav .logo .logo-light {
      display: block; }
  #topnav #topnav.scroll .menu-extras .menu-item .cart > a,
  #topnav #topnav.scroll .menu-extras .menu-item .search > a {
    color: #3d4146;
    line-height: 62px; }
  #topnav #topnav.scroll .menu-extras .menu-item .cart > a:hover,
  #topnav #topnav.scroll .menu-extras .menu-item .search > a:hover {
    color: #36508b; }

#topnav.no-back {
  background-color: transparent !important; }

#topnav.no-back a {
  background-color: transparent !important; }

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:focus {
  background-color: transparent;
  color: #ccf; }

#topnav.scroll.dark .menu-extras .menu-item .cart > a,
#topnav.scroll.dark .menu-extras .menu-item .search > a {
  color: #fff; }

.menu-extras {
  float: right; }
  .menu-extras .menu-item {
    float: left;
    border-right: 1px solid transparent; }
    .menu-extras .menu-item:first-child {
      border-left: 1px solid transparent; }

.menu-extras .menu-item .cart,
.menu-extras .menu-item .search {
  position: relative; }

.menu-extras .menu-item .cart > a,
.menu-extras .menu-item .search > a {
  display: block;
  padding: 0 25px;
  line-height: 72px;
  color: #fff;
  font-size: 16px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.job-desc {
  font-size: 14px;
  line-height: 2em; }
  .job-desc li {
    list-style: none; }

.text-box {
  margin: 25px 0;
  padding-right: 15px; }
  .text-box h4 {
    font-size: 13px;
    text-transform: uppercase; }
  .text-box hr {
    width: 40px;
    border-color: #ef2d56; }
    .text-box hr.right-align {
      margin-left: auto;
      margin-right: 0; }

.number-box {
  margin: 25px auto;
  max-width: 350px; }
  .number-box h2 {
    font-size: 80px;
    margin-top: 0; }
  .number-box h4 {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 2px; }
    .number-box h4:after {
      content: "";
      width: 35px;
      height: 1px;
      background-color: #ef2d56;
      display: block;
      margin: 20px 0; }

/*
  03
  Preloader
*/
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  text-align: center;
  background-color: #191b1d; }

#mask {
  position: relative;
  height: 30px;
  width: 50px;
  margin: 0 auto; }

#mask span {
  display: block;
  bottom: 0px;
  left: 0;
  width: 1px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  -webkit-animation: preloader 1.5s infinite ease-in-out;
  animation: preloader 1.5s infinite ease-in-out;
  -webkit-transform-origin: center center;
  transform-origin: center center; }

#mask span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: .2s;
  animation-delay: .2s; }

#mask span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: .4s;
  animation-delay: .4s; }

#mask span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: .6s;
  animation-delay: .6s; }

#mask span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: .8s;
  animation-delay: .8s; }

.anli-logo img {
  height: 3rem; }

/*
  04
  Home Section
*/
#home {
  margin-top: 80px; }
  #home .home-arrow-down {
    text-align: center;
    left: 48%;
    position: absolute;
    bottom: 30px; }

@media all and (max-width: 480px) {
  video {
    display: none; } }

@media screen and (min-width: 481px) {
  .fallback-img {
    display: none; } }

#video-wrapper {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

#video-wrapper video {
  position: absolute;
  width: 100%;
  height: 100%; }

#fallback-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.fallback-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black; }

#home {
  padding: 0;
  border: 0;
  background: transparent;
  max-height: 1170px; }

#home.bordered {
  border: 20px solid #fff !important; }

#home .centrize {
  z-index: 100; }

#home-slider {
  border: 0;
  margin: 0;
  height: 100%; }

#home-slider .slides {
  height: 100%; }

#home-slider .slides > li {
  height: 100%; }

#home-slider.kenburn .slide-image {
  transition: -webkit-transform 6s ease 0s;
  transition: transform 6s ease 0s;
  transition: transform 6s ease 0s, -webkit-transform 6s ease 0s;
  -webkit-transition: -webkit-transform 6s ease 0s;
  -moz-transition: -moz-transform 6s ease 0s;
  -moz-transform-origin: center center; }

#home-slider .flex-direction-nav > li {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 998; }

#home-slider .flex-direction-nav > li.flex-nav-prev {
  left: 0; }

#home-slider .flex-direction-nav > li.flex-nav-next {
  right: 0; }

#home-slider .flex-direction-nav > li > a {
  display: block;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

#home-slider .flex-direction-nav > li > a:hover {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8); }

.slide-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.slide-image.ff-fix {
  position: absolute; }

.home-content {
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  text-align: center;
  color: #EFF2F7;
  justify-content: space-around;
  padding: 0; }

.slide-content {
  height: 100%;
  vertical-align: middle;
  position: relative;
  z-index: 10;
  color: #eff2f7;
  text-align: center; }
  .slide-content.text-left {
    text-align: left; }
  .slide-content.bold-text h1 {
    font-weight: 200; }
    .slide-content.bold-text h1.upper {
      font-size: 75px; }
    .slide-content.bold-text h1.smaller {
      font-size: 40px;
      font-weight: 200;
      letter-spacing: 8px;
      margin: 35px 0; }
  .slide-content h1 {
    font-weight: 200;
    font-size: 90px; }
    .slide-content h1.upper {
      font-size: 64px;
      letter-spacing: 8px; }
    .slide-content h1.cursive {
      font-weight: 200;
      font-size: 130px; }
  .slide-content h2 {
    font-weight: 200;
    font-size: 75px;
    margin: 0; }
    .slide-content h2.upper {
      font-size: 48px;
      letter-spacing: 8px; }
    .slide-content h2.cursive {
      font-weight: 200; }
  .slide-content h6 {
    letter-spacing: 3px;
    font-size: 20px;
    color: #fff;
    font-weight: 200;
    margin: 35px 0; }
    .slide-content h6.upper {
      font-size: 16px; }
  .slide-content h3.cursive {
    font-size: 48px; }

#text-rotator h1 {
  margin: 0;
  padding: 25px 0;
  font-size: 75px; }
  #text-rotator h1.upper {
    font-size: 55px; }
  #text-rotator h1.bold-text {
    font-weight: 400; }

.particles-bg canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.nav-inside {
  overflow: hidden; }

.nav-inside .flex-direction-nav > li {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-transform: scale(0.7);
  transform: scale(0.7); }

.nav-inside .flex-direction-nav > li.flex-nav-prev {
  left: -45px;
  right: auto; }

.nav-inside .flex-direction-nav > li.flex-nav-prev > a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.nav-inside .flex-direction-nav > li.flex-nav-next {
  left: auto;
  right: -45px; }

.nav-inside .flex-direction-nav > li.flex-nav-next > a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.nav-inside .flex-direction-nav > li > a {
  width: 45px;
  height: 45px;
  display: block;
  background-color: #fff;
  line-height: 45px;
  text-align: center;
  color: #191b1d;
  text-decoration: none;
  opacity: 0.7;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.nav-inside .flex-direction-nav > li > a:hover {
  opacity: 1; }

.nav-inside .flex-direction-nav > li > a i {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  line-height: inherit; }

.nav-inside:hover .flex-direction-nav > li {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible; }

.nav-inside:hover .flex-direction-nav > li.flex-nav-prev {
  left: 0; }

.nav-inside:hover .flex-direction-nav > li.flex-nav-next {
  right: 0; }

.nav-outside .flex-direction-nav {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 100; }

.nav-outside .flex-direction-nav > li {
  display: inline-block; }

.nav-outside .flex-direction-nav > li:first-child {
  border-right: 1px solid #191b1d; }

.nav-outside .flex-direction-nav > li > a {
  width: 40px;
  height: 25px;
  display: block;
  line-height: 25px;
  text-align: center;
  color: #191b1d;
  text-decoration: none; }

.nav-outside .flex-direction-nav > li > a:hover {
  color: #EF2D56; }

.nav-outside .flex-direction-nav > li > a i {
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.nav-outside .flex-direction-nav > li > a.flex-prev:hover i {
  margin-right: 10px; }

.nav-outside .flex-direction-nav > li > a.flex-next:hover i {
  margin-left: 10px; }

.control-nav-dark .flex-control-nav {
  position: static;
  bottom: auto;
  margin-top: 20px; }

.control-nav-dark .flex-control-nav > li > a {
  border-color: #191b1d; }

.control-nav-dark .flex-control-nav > li > a.flex-active {
  border-color: #EF2D56; }

.control-nav-dark .flex-control-nav > li > a.flex-active:before {
  background-color: #EF2D56; }

.flex-control-nav {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  bottom: 20px;
  z-index: 100; }

.flex-control-nav > li {
  width: 25px;
  text-align: center;
  margin-bottom: 15px;
  display: inline-block; }

.flex-control-paging > li > a {
  width: 12px;
  height: 12px;
  text-indent: -9999px;
  display: block;
  margin: auto;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.65);
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.flex-control-paging > li > a.flex-active:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #fff; }

.progress {
  height: 1px;
  background-color: #ddd;
  margin-bottom: 25px;
  box-shadow: none; }

.progress-bar {
  background-color: #191b1d;
  box-shadow: none; }

.progress-bar.colored {
  background-color: #EF2D56; }

/*
  17
  Components
*/
.nav-tabs {
  border-bottom: 1px solid #eee;
  margin-bottom: 25px; }

.nav-tabs.center {
  text-align: center; }

.nav-tabs.center > li {
  display: inline-block;
  float: none; }

.nav-tabs > li > a {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  border-radius: 0;
  color: #191b1d; }

.nav-tabs > li > a:hover {
  background-color: transparent;
  border-color: transparent;
  color: #007BDB;
  font-weight: bold; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  font-weight: bold;
  color: #007BDB; }

.nav-tabs.outline {
  border-bottom: 1px solid #ddd; }

.nav-tabs.outline > li.active > a,
.nav-tabs.outline > li.active > a:focus,
.nav-tabs.outline > li.active > a:hover {
  background-color: #fff;
  outline: none; }

section.grey .nav-tabs.outline > li.active > a,
section.grey .nav-tabs.outline > li.active > a:focus,
section.grey .nav-tabs.outline > li.active > a:hover {
  background-color: #f7f7f7; }

section.dark .nav-tabs.outline > li > a {
  color: #fff; }

section.dark .nav-tabs.outline > li.active > a,
section.dark .nav-tabs.outline > li.active > a:focus,
section.dark .nav-tabs.outline > li.active > a:hover {
  background-color: #191b1d; }

#footer {
  position: relative;
  background-color: #3C434F;
  z-index: 10;
  color: #fff; }
  #footer a {
    color: white;
    line-height: 2em; }
    #footer a:hover {
      color: #eebb33; }

.centrize {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  position: relative; }

.v-center {
  display: table-cell;
  vertical-align: middle; }

.italic {
  font-style: italic; }

.cursive {
  font-family: 'Tangerine', cursive; }

.small-heading {
  font-size: 13px; }

.heading-font {
  font-weight: 400; }

.upper {
  text-transform: uppercase; }

.ov-v {
  overflow: visible; }

.ov-h {
  overflow: hidden; }

.b-0 {
  border: 0 !important; }

.bt-0 {
  border-top: 0 !important; }

.br-0 {
  border-right: 0 !important; }

.bb-0 {
  border-bottom: 0 !important; }

.bl-0 {
  border-left: 0 !important; }

.border-top {
  border-top: 1px solid #e7e7e7; }

.border-right {
  border-right: 1px solid #e7e7e7; }

.border-bottom {
  border-bottom: 1px solid #e7e7e7; }

.border-left {
  border-left: 1px solid #e7e7e7; }

.m-0 {
  margin: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-100 {
  margin-top: 100px !important; }

.p-0 {
  padding: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pb-15 {
  padding-bottom: 15px; }

.pr-15 {
  padding-right: 15px; }

.pl-15 {
  padding-left: 15px; }

.pt-15 {
  padding-top: 15px; }

.pb-25 {
  padding-bottom: 25px; }

.pr-25 {
  padding-right: 25px; }

.pl-25 {
  padding-left: 25px; }

.pt-25 {
  padding-top: 25px; }

.pb-50 {
  padding-bottom: 50px; }

.pr-50 {
  padding-right: 50px; }

.pl-50 {
  padding-left: 50px; }

.pt-50 {
  padding-top: 50px; }

.fw-400 {
  font-weight: 400 !important; }

.ul-no-p {
  list-style-type: none;
  padding-left: 0; }

.grey-text {
  color: #79818a; }

.white-text {
  color: #fff; }

.black-text {
  color: #191b1d !important; }

.red {
  color: #36508b !important; }

.lead-text {
  font-size: 21px;
  text-align: justify; }

.blue-dot {
  color: #36508b; }

.alt-paragraph {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6; }

.invisible {
  opacity: 0; }

.fade-in-top {
  -webkit-animation: fadeInTop 1.5s both;
  animation: fadeInTop 1.5s both; }

/*
  24
  Media Queries
*/
@media (min-width: 769px) {
  #topnav.top-space {
    top: 20px; }
  #topnav.scroll {
    top: 0; }
  #topnav .navbar-toggle {
    display: none; }
  #topnav #navigation {
    display: block !important; }
  .txt-md-right {
    text-align: right; }
  .txt-md-right .title hr {
    margin-right: 0;
    margin-left: auto; }
  .txt-md-center {
    text-align: center; }
  .title.txt-md-right hr {
    margin-right: 0;
    margin-left: auto; }
  .md-pull-right {
    float: right; }
  .md-pull-left {
    float: left; } }

@media (max-width: 768px) {
  #text-rotator h1 {
    font-size: 30px; }
  #text-rotator h1.upper {
    font-size: 40px; }
  #topnav {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    min-height: 52px !important;
    border-bottom: 1px solid #666; }
  #topnav .navbar-toggle {
    height: 52px !important; }
  #topnav .navbar-toggle .lines {
    margin: 19px auto !important; }
  #topnav.dark {
    background-color: #191b1d;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
  #topnav.dark #navigation {
    background-color: #191b1d;
    border-color: #3d4146; }
  #topnav .navigation-menu > li > a {
    color: #fff;
    margin: 0 !important;
    padding: 21px 15px !important;
    display: block; }
  .navigation-menu > li > .active {
    background: #30588f !important;
    border: none !important; }
  .hideBox {
    height: auto !important;
    min-height: 40px !important; }
  .dropdown-menu {
    position: relative;
    float: none;
    width: 100% !important; }
  .dropdown-menu > li {
    text-align: left;
    width: 88%;
    margin-left: 6%; }
  #topnav .navigation-menu > li > a:hover {
    color: #fff !important; }
  #topnav.dark .navigation-menu > li .submenu li a {
    color: #fff; }
  #topnav.dark .menu-extras .menu-item {
    border-color: #3d4146; }
  #topnav.dark .menu-extras .menu-item .cart > a,
  #topnav.dark .menu-extras .menu-item .search > a {
    color: #fff; }
  #topnav.dark .logo .logo-light {
    display: block; }
  #topnav.dark .navbar-toggle span {
    background-color: #fff; }
  #topnav.dark .navbar-toggle:hover span {
    background-color: #EF2D56; }
  #topnav .container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px; }
  #topnav .navigation-menu {
    float: none;
    max-height: 400px; }
  #topnav .navigation-menu > li {
    float: none; }
  #topnav .navigation-menu > li > a:hover {
    color: #EF2D56; }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px; }
  #topnav .menu-extras .menu-item .cart > a,
  #topnav .menu-extras .menu-item .search > a {
    color: #3d4146;
    line-height: 62px;
    position: relative; }
  #topnav .menu-extras .menu-item .cart > a:hover,
  #topnav .menu-extras .menu-item .search > a:hover {
    color: #EF2D56; }
  #topnav .navbar-header {
    float: left; }
  #topnav .menu-extras {
    margin-right: -15px; }
  #topnav .logo {
    padding: 11px 0; }
  #topnav .logo .logo-light {
    display: none; }
  #topnav .logo .logo-dark {
    display: block;
    height: 30px; }
  #navigation {
    position: absolute;
    top: 53px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    background-color: #254f88; }
  #navigation.open {
    display: block;
    overflow-y: auto; }
  .blog-masonry.three-col .masonry-post {
    width: 50%; }
  .countdown li {
    margin-right: 25px; }
  .counter {
    margin: 50px 0; }
  #footer .copy-text,
  #footer .footer-social {
    text-align: center; }
  .map-side {
    padding: 0;
    margin-top: -100px;
    margin-bottom: 25px; }
  .map-side #map {
    min-height: 350px;
    width: 100%;
    position: relative !important; } }

@media (min-width: 768px) and (max-width: 767px) {
  #works.two-col .work-item {
    width: 50%; }
  #works.three-col .work-item {
    width: 50%; }
  #works.four-col .work-item {
    width: 50%; }
  .title.txt-sm-center hr {
    margin-left: auto;
    margin-right: auto; }
  .txt-sm-right {
    text-align: right; }
  .txt-sm-right .title hr {
    margin-left: auto;
    margin-right: 0; }
  .txt-sm-center {
    text-align: center; }
  .sm-pull-right {
    float: right; }
  .sm-pull-left {
    float: left; }
  .rs-menu.image-right .rs-menu-media {
    left: 0;
    right: auto; }
  .rs-menu.image-right .rs-menu-body {
    margin-right: 0;
    margin-left: 50%; }
  .rs-menu .rs-menu-body {
    border: 1px solid #e7e7e7; }
  .countdown-wrap .countdown span {
    width: 75px;
    height: 75px;
    line-height: 75px;
    font-size: 35px; } }

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0; }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0; }
  .navbar-toggle {
    display: block; }
  .menu-extras .menu-item .search:hover .search-form {
    margin-top: 0;
    opacity: 1;
    visibility: visible; }
  .menu-extras .menu-item .cart:hover .shopping-cart {
    margin-top: 0;
    opacity: 1;
    visibility: visible; } }

@media (max-width: 767px) {
  #text-rotator h1 {
    font-size: 55px; }
  #text-rotator h1.upper {
    font-size: 32px; }
  section.splash .title h2 {
    font-size: 48px; }
  .title h2 {
    font-size: 46px;
    font-weight: 400 !important; }
  .title h3 {
    font-size: 20px !important;
    font-weight: 400 !important; }
  .title.txt-xs-center hr {
    margin-left: auto;
    margin-right: auto; }
  .txt-xs-center {
    text-align: center; }
  .xs-pull-right {
    float: right; }
  .xs-pull-left {
    float: left; }
  .slide-content.bold-text h1.upper {
    font-size: 45px; }
  .slide-content h1 {
    font-size: 55px; }
  .slide-content h1.upper {
    font-size: 45px;
    letter-spacing: 4px; }
  .slide-content h1.cursive {
    font-size: 80px; }
  .slide-content h2.upper {
    font-size: 38px; }
  .slide-content h6 {
    font-size: 16px; }
  .menu-extras .menu-item .search,
  .menu-extras .menu-item .cart {
    position: static; }
  .menu-extras .menu-item .search.open .search-form {
    margin-top: 0;
    opacity: 1;
    visibility: visible; }
  .menu-extras .menu-item .cart.open .shopping-cart {
    margin-top: 0;
    opacity: 1;
    visibility: visible; } }

@media (max-width: 1170px) {
  .section-content {
    padding: 0 15px; } }

@media (max-height: 400px) {
  .gallery-image {
    max-width: 60%; } }

/*
  25
  Animations
*/
@-webkit-keyframes animServiceHr {
  0% {
    width: 80px; }
  50% {
    width: 30px; }
  75% {
    width: 100px; }
  100% {
    width: 40px; } }

@keyframes animServiceHr {
  0% {
    width: 80px; }
  50% {
    width: 30px; }
  75% {
    width: 100px; }
  100% {
    width: 40px; } }

@-webkit-keyframes animServiceIcon {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  75% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes animServiceIcon {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  75% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes preloader {
  0% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #fff; }
  25% {
    height: 60px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background-color: #138DFF; }
  50% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #61B3FF; }
  100% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #61B3FF; } }

@keyframes preloader {
  0% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #61B3FF; }
  25% {
    height: 60px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background-color: #003C74; }
  50% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #61B3FF; }
  100% {
    height: 30px;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    background-color: #61B3FF; } }

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(75px);
    transform: translateY(75px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translateY(75px);
    transform: translateY(75px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes animClient {
  25% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes animClient {
  25% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

svg.recruiterbox {
  width: 1em;
  height: 1em;
  margin-bottom: -0.1em; }

.triangle {
  height: 0.8em; }

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

#slideshow {
  height: inherit;
  width: 100%;
  max-height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative; }

#slideshow ul {
  margin-left: auto;
  margin-right: auto;
  height: inherit;
  width: 100%; }

#slideshow ul li {
  height: 400px;
  width: 100%;
  background-size: 100% 100%; }

@media (min-width: 768px) {
  #slideshow ul {
    height: inherit; }
  p {
    display: block; }
  #home-slider h3 {
    margin: 0; } }

#slideshow ul {
  display: block;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
  max-height: inherit; }

#slideshow li {
  display: inline-block;
  height: inherit;
  width: 100%;
  max-width: inherit;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  max-height: inherit; }

.banner-container {
  width: 100%;
  padding: 0;
  height: 100%; }

@media (min-width: 769px) {
  section {
    padding-top: 100px; }
  .buttons {
    display: flex;
    justify-content: center; }
  .buttons > * {
    padding: 0 40px; } }

.brand-heading {
  display: block;
  text-align: left;
  max-width: 1170px;
  margin: 40px auto 20px auto;
  color: #36508b; }
  .brand-heading .header-box {
    padding: 10px;
    border: 1px solid #d4d4d8;
    width: 700px;
    height: 200px;
    margin-left: -15px;
    position: relative; }
    .brand-heading .header-box h1 {
      margin-top: 50px; }
    .brand-heading .header-box .content {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 70px;
      z-index: 20;
      padding: 10px 20px 0px 0px; }

@media (max-width: 992px) {
  .header-box {
    width: 100% !important; }
  .header-box .content h1 {
    font-size: 18px; }
  #home {
    margin-top: 52px;
    max-height: 330px; } }

#home-index {
  margin-top: 80px; }
  #home-index #slideshow ul li {
    height: auto; }
  #home-index .brand-heading {
    color: white;
    margin-top: 16%; }
    #home-index .brand-heading .header-box {
      border: 1px solid rgba(255, 255, 255, 0.2);
      text-align: center;
      height: 208px;
      width: 1000px;
      margin: 10px auto; }
      #home-index .brand-heading .header-box:before {
        content: '';
        border: 3px solid #d4d4d8;
        background-color: transparent;
        height: 208px; }
      #home-index .brand-heading .header-box .content {
        position: relative;
        padding: 0; }
        #home-index .brand-heading .header-box .content h2 {
          line-height: 208px;
          margin: 0;
          padding: 0;
          margin-left: 10px; }

@media (max-width: 768px) {
  .titleH3 {
    font-size: 26px; }
  .title p {
    font-size: 14px; }
  .about-body .about-content {
    padding: 20px; }
  h1 {
    font-size: 30px; }
  body {
    font-size: 14px; }
  #slideshow {
    margin-top: 0; }
  #home-index h2 {
    font-size: 21px;
    padding-left: 12px;
    line-height: 100px !important; }
  #home-index .brand-heading .header-box {
    width: 90% !important;
    height: 90px !important; }
    #home-index .brand-heading .header-box:before {
      height: 90px !important; }
  .try-box {
    height: auto !important; } }

.herow .span1 {
  vertical-align: top;
  box-sizing: border-box;
  display: inline-block;
  float: none;
  margin-right: -0.31em;
  width: 20%;
  padding-top: 24px;
  padding-left: 24px;
  margin-left: 0%; }

.img-title-box {
  padding: 35px 10px 15px;
  background-color: white;
  min-height: 350px;
  box-shadow: 0 1px 2px 0 rgba(48, 48, 77, 0.05), 0 1px 7.5px 0 rgba(48, 48, 77, 0.04); }
  .img-title-box .img-title {
    font-size: 18px;
    text-align: center;
    padding: 10px;
    font-weight: 500; }
  .img-title-box .content {
    padding: 15px;
    font-size: 14px;
    color: #666;
    min-height: 140px; }

a:focus, a:hover {
  text-decoration: none;
  color: #6178ea; }

.collapse-clean .panel {
  background-color: transparent;
  box-shadow: none; }

.collapse-clean .panel-default {
  border-color: transparent; }

.collapse-clean .panel-default > .panel-heading {
  background-color: transparent;
  border-color: transparent; }

.collapse-clean .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top-color: transparent; }

.try-box {
  background-color: rgba(54, 80, 139, 0.9);
  width: 100%;
  height: 80px;
  min-height: 80px;
  position: fixed;
  bottom: 0;
  z-index: 20000;
  color: #fff;
  border-top: 1px solid #2f4579; }
  .try-box .try-content {
    margin: 0 auto;
    max-width: 1170px;
    text-align: center; }
  .try-box .try-form {
    margin-bottom: 20px; }
  .try-box button {
    background: linear-gradient(135deg, #ffb932, #ff7c25);
    border: none;
    color: #36508b;
    font-weight: bold;
    padding: 7px 20px; }
